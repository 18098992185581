.loading_project {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.startup_main .node-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 15px;
    grid-auto-rows: minmax(100px, auto);
}

.startup_main .load-project {
    width: 100%;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.startup_main {
    color: white;
    height: 100%;  
    overflow-y: auto;
    scrollbar-color: rebeccapurple green;
    scrollbar-width: thin;
    padding-left: 24px;
    padding-right: 24px;
    font-family: "Clarity City";
}

.startup_main h2 {
    font-size: 32px;
    font-weight: 200;
    font-family: "Clarity City";
}

.startup_main h3 {
    font-size: min(24px,2vh);
    font-weight: 200;
    font-family: "Clarity City";
}

.startup_main .node-grid cds-card {
    --padding: 0;
}

.startup_preview {
    width: 21%;
    padding-left: 1%;
}

.startup_navigation {
    width: 19%;
    padding-right: 1%;
}

.startup_menu {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.startup_category {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.project {
    height: 65%;
}

.projectCard {
    cursor: pointer;
    height: initial;
    transition: scale 200ms;
}

cds-card .date {
    color: #bdbcbc;
    font-size: min(13px,1.2vh);
}

cds-card .node-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 50px;
}

.preview-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.project-settings {
    display: none;
}

cds-card:hover .project-settings {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1b2b32;
}

cds-card:hover .project-settings:hover {
    background-color: #526d79;
}
