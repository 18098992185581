body {
    color: #EAEDF0;
}

.template-metadata h3 {
    font-size: 0.9rem;
    font-weight: 200;
    font-family: "Clarity City";
    margin-bottom: 0.5vh;
}

.template-description {
    font-size: 0.7rem;
    font-weight: 200;
    font-family: "Clarity City";
    color: #bdbcbc;
}
