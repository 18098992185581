.svg-font-color svg > path {
    fill: var(--ifm-font-color-base);
  }
  
  .queryBuilder {
    min-width: 420px;
    font-family: "Clarity City";
  }
  
  .validateQuery .queryBuilder .ruleGroup.queryBuilder-invalid {
    background-color: rgba(102, 51, 153, 0.4);
  }
  .validateQuery .queryBuilder .ruleGroup.queryBuilder-invalid .ruleGroup-addRule {
    font-weight: bold !important;
  }
  .validateQuery .queryBuilder .ruleGroup.queryBuilder-invalid > .ruleGroup-header::after {
    content: "Empty groups are considered invalid. Avoid them by using addRuleToNewGroups.";
    color: white;
  }
  .validateQuery .queryBuilder .rule.queryBuilder-invalid .rule-value {
    background-color: rgba(102, 51, 153, 0.4);
  }
  .validateQuery .queryBuilder .rule.queryBuilder-invalid .rule-value::placeholder {
    color: #47246b;
  }
  
  html[data-theme="dark"]
    .validateQuery
    .queryBuilder
    .rule.queryBuilder-invalid
    .rule-value::placeholder {
    color: #945ec9;
  }
  
  .justifiedLayout .queryBuilder .ruleGroup-addGroup + button.ruleGroup-cloneGroup,
  .justifiedLayout .queryBuilder .ruleGroup-addGroup + button.ruleGroup-lock,
  .justifiedLayout .queryBuilder .ruleGroup-addGroup + button.ruleGroup-remove,
  .justifiedLayout .queryBuilder .rule-operators + button.rule-cloneRule,
  .justifiedLayout .queryBuilder .rule-operators + button.rule-lock,
  .justifiedLayout .queryBuilder .rule-operators + button.rule-remove,
  .justifiedLayout .queryBuilder .rule-value + button.rule-cloneRule,
  .justifiedLayout .queryBuilder .rule-value + button.rule-lock,
  .justifiedLayout .queryBuilder .rule-value + button.rule-remove,
  .justifiedLayout .queryBuilder .control + button.rule-cloneRule,
  .justifiedLayout .queryBuilder .control + button.rule-lock,
  .justifiedLayout .queryBuilder .control + button.rule-remove,
  .justifiedLayout .queryBuilder .chakra-select__wrapper + button.rule-cloneRule,
  .justifiedLayout .queryBuilder .chakra-select__wrapper + button.rule-lock,
  .justifiedLayout .queryBuilder .chakra-select__wrapper + button.rule-remove {
    margin-left: auto;
  }

  .queryBuilder .ruleGroup {
    padding-left: 10px;
  }

  .queryBuilder .rule-fields {
    font-size: 8px;
  }

  .queryBuilder .rule-operators {
    font-size: 8px;
  }

  .queryBuilder .rule-value {
    font-size: 8px;
  }

  .queryBuilder .rule-remove {
    font-size: 8px;
  }

  .queryBuilder .ruleGroup-header .ruleGroup-combinators {
    font-size: 8px;
  }

  .queryBuilder .ruleGroup-header .ruleGroup-addRule {
    font-size: 8px;
  }

  .queryBuilder .ruleGroup-header .ruleGroup-addGroup {
    font-size: 8px;
  }

  .queryBuilder .ruleGroup-header .ruleGroup-remove {
    font-size: 8px;
  }

  .queryBuilder .queryBuilder-dragHandle {
    font-size: 12px;
    padding-right: 2px;
  }

.filter-node .parameters {
  max-width: 450px;
}


.ruleGroup,
.rule {
  position: relative;
}

.ruleGroup-body {
  margin-left: 0.5rem;
}

.rule::before,
.ruleGroup .ruleGroup::before {
  content: '';
  width: 0.5rem;
  top: -0.5rem;
  left: -0.75rem;
  height: calc(50% + 0.5rem);
  border-color: #5F99B8;
  border-style: solid;
  position: absolute;
  border-width: 0 0 2px 2px;
}

.rule:first-child::before,
.ruleGroup .ruleGroup:first-child::before {
  height: calc(50% + 0.5rem);
}

.rule:last-child::before,
.ruleGroup .ruleGroup:last-child::before {
  border-radius: 0 0 0 4px;
}

.rule::after,
.ruleGroup .ruleGroup::after {
  content: '';
  width: 0.5rem;
  top: 50%;
  left: -0.75rem;
  /* +1px to account for `.ruleGroup { border-width: 1px }` */
  height: calc(50% + 1px);
  border-color: #5F99B8;
  border-style: solid;
  position: absolute;
  border-width: 0 0 0 2px;
}

.ruleGroup .ruleGroup::before,
.ruleGroup .ruleGroup::after {
  /* -1px to account for `.ruleGroup { border-width: 1px }` */
  left: calc(-0.75rem - 1px);
}

.rule:last-child::after,
.ruleGroup .ruleGroup:last-child::after {
  display: none;
}


.parameters .filter-area {
  border-radius: 2px;
  padding: 3px;
  background-color: #2E4A59;
}