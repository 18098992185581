.react-flow .react-flow__handle {
    width: 7px;
    height: 16px;
    border: 1px;
  }
  
  .react-flow .react-flow__handle-right {
    right: -7px;
    border-radius: 0 1px 1px 0;
  }

  .react-flow .react-flow__handle-right[outputtype="full"] {
    height: 100%;
  }

  .react-flow .react-flow__handle-left {
    left: -7px;
    border-radius: 1px 0 0 1px;
  }

  .wrapper {
    flex-grow: 1;
    height: 100%;
  }

