.react-flow__minimap-mask {
    fill: #324F62;
  }

.react-flow__minimap {
    background-color: #1b2a32;
  }

.react-flow__minimap-node {
    fill: #28404D;
    stroke: none;
  }

.react-flow__controls-button {
    width: 24px;
    height: 24px;
    background-color: #324F62;
    border-bottom: 1px solid #212134;
    fill: white
  }

::-webkit-scrollbar-track {
    background-color: #1b2b32;
  }
  
::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
  }
  
::-webkit-scrollbar-thumb {
    background-color: #324F62;
  }