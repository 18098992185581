.menu-bar {
    display: flex;
    align-items: center;
}

.cds-tabs-mock {
    display: flex;
    margin-block-start: 1em;
    margin-block-end: 1em;
}

.cds-tabs-mock button {
    display: flex;
    align-items: center;
    align-content: center;
    justify-items: center;
    gap: var(--cds-global-space-5);
    background: transparent;
    border: 0;
    color: white;
    padding: var(--cds-global-space-5) var(--cds-global-space-6) var(--cds-global-space-4) var(--cds-global-space-6);
    box-shadow: inset 0 calc(var(--cds-alias-object-border-width-100) * -1) 0 0 var(--cds-alias-object-border-color);
    min-height: var(--cds-alias-object-interaction-touch-target);
    cursor: pointer;
    font-size: min(24px,2vh);
    font-family: "Clarity City";
    font-weight: 200;
}

.cds-tabs-mock button cds-icon {
    margin-top: -1px;
}

.cds-tabs-mock button:hover,
.cds-tabs-mock button[hover] {
    box-shadow: inset 0 calc(var(--cds-alias-object-border-width-300) * -1) 0 0
        white;
    /* color: var(--cds-alias-object-interaction-color-hover); */
}

.cds-tabs-mock button:hover cds-icon,
.cds-tabs-mock button[hover] cds-icon,
.cds-tabs-mock button cds-icon:hover {
    cursor: pointer;
}

.cds-tabs-mock button.active,
.cds-tabs-mock button:active,
.cds-tabs-mock button[active] {
    color: var(--cds-alias-object-interaction-background-highlight);
    box-shadow: inset 0 calc(var(--cds-alias-object-border-width-300) * -1) 0 0
        var(--cds-alias-object-interaction-background-highlight);
}

.cds-tabs-mock button.active cds-icon,
.cds-tabs-mock button:active cds-icon,
.cds-tabs-mock button[active] cds-icon {
    color: var(--cds-alias-object-interaction-background-highlight);
    box-shadow: inset 0 calc(var(--cds-alias-object-border-width-300) * -1) 0 0
        var(--cds-alias-object-interaction-background-highlight);
}

.cds-tabs-mock button[selected] {
    box-shadow: inset 0 calc(var(--cds-alias-object-border-width-300) * -1) 0 0
        var(--cds-alias-object-interaction-background-highlight);
    color: var(--cds-alias-object-interaction-color-selected);
}

.cds-tabs-mock button[disabled] {
    color: var(--cds-alias-object-interaction-color-disabled);
    box-shadow: inset 0 calc(var(--cds-alias-object-border-width-100) * -1) 0 0 var(--cds-alias-object-border-color);
    cursor: not-allowed;
}

.cds-tabs-mock button[disabled] cds-icon {
    color: var(--cds-alias-object-interaction-color-disabled);
    cursor: not-allowed;
}

.cds-tabs-mock button[focused] {
    outline: var(--cds-alias-object-interaction-outline);
    outline-offset: calc(var(--cds-alias-object-interaction-outline-offset) * -1);
}

@media (-webkit-min-device-pixel-ratio: 0) {
.cds-tabs-mock button[focused] {
    outline: var(--cds-alias-object-interaction-outline-webkit);
}
}