h3 {
    font-size: min(24px,2vh);
    font-weight: 200;
    font-family: "Clarity City";
}

h4 {
    font-size: min(18px,1.5vh);
    font-weight: 200;
    font-family: "Clarity City";
}

cds-card .template-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    justify-content: space-between;
}