.export-options {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}


.export-options cds-card {
    width: 100%;
}

.export-options cds-card .card-content {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.card-icon {
    font-size: 9em;
}

.card-icon-xl {
    font-size: 15em;
}

.export-options cds-card .card-content .card-title {
    font-size: 1.5em;
}

.exportCard {
    cursor: pointer;
    margin: 6px;
    height: initial;
    transition: scale 200ms;
}

.export-selection {
    width: 100%;
    height: 30vh;
    display: flex;
}

.export-selection .card-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}