.react-flow__node {
    height: fit-content;
    border: 1px solid #acbac3;
    border-radius: 1px;
    /* background: #28404D; */
    font-family: "Clarity City";
  }
    
  .react-flow__node .header label {
    display: block;
    color: #EAEDF0;
    font-size: 18px;
    font-weight: 400;
    font-family: "Clarity City";
  }

  .react-flow__node .header {
    display: flex;
    align-items: center;
    padding: 8px 5px 8px 5px;
    justify-content: space-between;
    border-bottom: 1px solid #acbac3;
  }

  .react-flow__node .node-info {
    display: flex;
    justify-content: center;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .react-flow__node .node-expand {
    display: flex;
    justify-content: center;
    margin-top: 2px;
  }
  

  .react-flow__node .parameters {
    display: flex;
    flex-direction: column;
    color: #CCCCCC;
    font-size: 8px;
    padding: 5px 5px 5px 5px;
    max-width: 300px;
  }

  .react-flow__node .parameters .input {
    position: relative;
    margin: 4px 0 10px 0;
    left: -5px;
  }

  .react-flow__node .parameters .output {
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    margin: 4px 0 10px 0;
    right: -5px;
  }

  .react-flow__node h3 {
    color: white;
    font-weight:  600;
  }

  .react-flow__node .parameters hr {
    width:100%;
    border: 1px solid; 
    color: #179BD3;
  }

  .react-flow__node:hover{
    border: 1px solid #cfd8de;
  }

  .react-flow__node.selected{
    border: 1px solid #0096d6;
  }

  .custom-button {
    cursor: pointer;
    color: #0072A3;
  }

  .custom-button:hover {
    color: #CCCCCC;
  }

input {
    font-size: 6px;
  }

.form {
  background: #28404D;
}

.react-flow .react-flow__handle[datatype="Model"] {
  background-color: #ffa600;
}

.react-flow .react-flow__handle[datatype="Dataset"] {
  background-color: #0072A3;
}

.react-flow .react-flow__handle[datatype="Workflow"] {
  background-color: #5c25a8;
}

.react-flow__node [datatype="Dataset"] {
  color: #558da4;
}

.react-flow__node [datatype="Model"] {
  color: #c1984e;
}

.react-flow__node [datatype="Workflow"] {
  color: #8566b0;
}

cds-checkbox {
  display: flex;
  align-items: center;
}
