.workspace_list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px;
    grid-auto-rows: minmax(100px, auto);
    width: 100%;
}

.workspace-card-content {
    display: flex;
    justify-content: space-around;
    height: 100%;
    align-items: center;
    cursor: pointer;
}