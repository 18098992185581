
.nodeCard {
    cursor: pointer;
    margin: 6px;
    height: initial;
    transition: scale 200ms;
}

cds-card:hover {
    scale: 1.03;
}

cds-card .card-description {
    color: gainsboro;
}

cds-card .card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100px;
}

cds-card .card-io {
    color: #bdbcbc;
    font-size: 13px;
}

.library_main {
    display: flex;
    width: 100%;
}

.node-row {
    display: flex;
    flex-direction: row;
}

.library-node-grid {
    max-width: 95%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);
}

.library_navigation {
    width: 30%;
    color: white;
}

.library_content {
    overflow-y: scroll;
    height: 60vh;
    width: 70%;
    color: white;
}

.library_category {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

cds-modal-header {
    color: white;
}

cds-modal {
    z-index: 100;
}