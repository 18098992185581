.edit_area {
    background-color: rgba(50, 79, 98, 0.3);
    color: white;
    font-family: "Clarity City";
    max-width: 350px;
    min-width: 100px;
    min-height: 100px;
    border: none;
}

.comment_header{
    top: -30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 30px;
    background-color: rgb(27, 43, 50);
}

.comment_node:hover .comment_header{
    opacity: 1;
}


.react-flow__node-comment {
    border: none;
}

.react-flow__node-comment:hover {
    border: none;
}

.react-flow__node-comment.selected {
    border: none;
}

.markdown {
    max-width: 350px;
    min-width: 100px;
    display: block;
    color: white;
    font-family: "Clarity City";
    font-size: 13.3333px;
    overflow-wrap: break-word;
    margin-top: 0;
    margin-bottom: 0;
  }

.markdown h1 {
    margin-top: 0;
    margin-bottom: 5px;
}

.markdown h2 {
    margin-top: 0;
    margin-bottom: 5px;
}

.markdown p {
    margin-top: 0;
    margin-bottom: 5px;
}